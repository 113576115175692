import React from "react";
import { Frame } from "component/Frame";
import useDisclosure from "hooks/useDisclosure";
import DashboardModal from "component/Modal/Modal";
import PasswordModal from "./PasswordModal";
import { useSidebar } from "component/Sidebar/useSidebar";

const SystemSettings = ({
  onNotificationClick,
}: {
  onNotificationClick: () => void;
}) => {
  const {
    isOpen: openPasswordModal,
    open: onOpenPasswordModal,
    close: closePasswordModal,
  } = useDisclosure();
  const { isDarkTheme } = useSidebar();

  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";
  return (
    <Frame rmPadding>
      <div className="flex flex-col">
        <div
          className=" border-b  border-[#999898] p-2 px-3 cursor-pointer"
          onClick={onNotificationClick}
        >
          <p className={`${toggleTextTheme} font-medium text-base `}>
            Notifications
          </p>
          <p className="text-[#999898] font-normal text-[11px]">
            Customize notifications
          </p>
        </div>
        <div
          className="border-b  border-[#999898] p-3 px-3 cursor-pointer"
          onClick={onOpenPasswordModal}
        >
          <p
            className={`${toggleTextTheme} font-medium text-base  border-[#999898]`}
          >
            Change Password
          </p>
          <p className="text-[#999898] font-normal text-[11px]">
            Choose a new password
          </p>
        </div>
        <div className="p-3 px-3">
          <p
            className={`${toggleTextTheme} font-medium text-base border-[#999898]`}
          >
            Help Center
          </p>
          <p className="text-[#999898] font-normal text-[11px]">
            Contact support team
          </p>
        </div>
        <div className="p-1 bg-gray-100 rounded-lg shadow-md">
          <p
            className={`${toggleTextTheme} font-normal text-[15px] text-gray-800 border-b-2 border-gray-300 pb-2`}
          >
            chaxotechnologies@gmail.com &nbsp;|&nbsp; 09115459143
            {/* <span className="text-[crimson]">chaxotechnologies@gmail.com</span> &nbsp;|&nbsp; 09115459143 */}

            {/* <span className="color-[crimson]">chaxotechnologies@gmail.com</span> &nbsp;|&nbsp; 09115459143 */}
          </p>
        </div>
      </div>
      {/* modal wrap start */}
      <DashboardModal
        open={openPasswordModal}
        onClose={closePasswordModal}
        width={"492px"}
      >
        <PasswordModal closeModal={closePasswordModal} />
      </DashboardModal>
      {/* modal wrap end */}
    </Frame>
  );
};

export default SystemSettings;
